<script setup lang="ts">
import { reactive, onMounted, ref } from 'vue'
import axios from 'axios'
import DataTable from 'primevue/datatable'
import Button from 'primevue/button'
import Column from 'primevue/column'
import Textarea from 'primevue/textarea'
import { useAuthStore } from '@/stores/auth'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import Checkbox from 'primevue/checkbox'
import { useToast } from 'primevue/usetoast'

const toast = useToast()
const store = useAuthStore()

const loading = ref(false)
const visible = ref(false)
const messageValue = ref('')
const selectedUsers = ref([])

const users = reactive({
  list: []
})

function loadUsersList() {
  loading.value = true
  axios
    .post(
      '/users/all',
      {},
      {
        headers: {
          Authorization: 'Bearer ' + store.token,
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      users.list = response.data
    })
}

onMounted(() => {
  loadUsersList()
})

function sendMessages() {
  if (!messageValue.value || messageValue.value === '') {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Не буду отправлять пустое сообщение',
      life: 3000
    })
    return false
  }
  const userIds = selectedUsers.value.map((singleUser: any) => singleUser.user_id)
  userIds.forEach((singleId, index) => {
    setTimeout(
      ((id, message) =>
        function () {
          axios
            .post(
              '/message/',
              { to: id, message: message },
              {
                headers: {
                  Authorization: 'Bearer ' + store.token,
                  'Content-Type': 'application/json'
                }
              }
            )
            .then((response) => {
              if (response.status === 200) {
                selectedUsers.value = selectedUsers.value.filter(
                  (singlePerson: any) => singlePerson.user_id != singleId
                )
                toast.add({
                  severity: 'info',
                  summary: 'Info',
                  detail: `Сообщение отправлено ${id}`,
                  life: 1500
                })
              } else {
                toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: `Ошибка отправки ${id}`,
                  life: 3000
                })
              }
            })
        })(singleId, messageValue.value),
      1000 * index
    )
  })

  messageValue.value = ''
}
</script>

<template>
  <main>
    <Splitter class="mb-8 min-h-screen items-start">
      <SplitterPanel class="min-h-screen flex justify-center" :size="75">
        <DataTable
          sortField="user_id"
          :sortOrder="-1"
          :value="users.list"
          :globalFilterFields="['shop_name']"
          v-model:selection="selectedUsers"
          filterDisplay="menu"
        >
          <Column selectionMode="multiple"></Column>
          <Column sortable field="user_id" header="user_id"></Column>
          <Column sortable field="username" header="Логин"></Column>
          <Column sortable field="shop_name" header="Компания"></Column>
          <Column sortable field="name" header="Имя"></Column>
          <Column sortable field="tilda_user_name" header="tilda_user_name"></Column>
          <Column field="badge_user_id" header="badge_user_id"></Column>
          <Column field="belt" header="Пояс"></Column>
        </DataTable>
      </SplitterPanel>
      <SplitterPanel class="min-h-screen flex flex-col" :size="25">
        <Textarea
          v-model="messageValue"
          placeholder="Напишите сообщение..."
          class="w-full px-2 py-1 rounded-md border-2 border-blue-"
          style="height: 300px"
        />
        <Button @click="sendMessages">Отправить сообщение</Button>
      </SplitterPanel>
    </Splitter>
  </main>
</template>
