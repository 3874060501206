import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ChatView from '@/views/ChatView.vue'
import LoginView from '@/views/LoginView.vue'
import TemplateView from '@/views/TemplateView.vue'
import HelpView from '@/views/HelpView.vue'
import MailingView from '@/views/MailingView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginView
    },
    {
      path: '/list',
      name: 'list',
      component: HomeView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/templatesView',
      name: 'templatesView',
      component: TemplateView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/chat/:id',
      name: 'chat',
      component: ChatView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/helpView',
      name: 'helpView',
      component: HelpView
    },
    {
      path: '/mailingView',
      name: 'mailingView',
      component: MailingView
    }
  ]
})

export default router
